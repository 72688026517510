import React from 'react';
import NotesIcon from '@mui/icons-material/Notes';
import SavingsIcon from '@mui/icons-material/Savings';
import WorkIcon from '@mui/icons-material/Work';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import SchoolIcon from '@mui/icons-material/School';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import {IconsEnum} from "../../constants/icons.enum";
import { Book } from "../../redux/reducers/books";

const iconTypeToIcon: Record<IconsEnum, JSX.Element> = {
  [IconsEnum.Notes]: <NotesIcon />,
  [IconsEnum.Work]: <WorkIcon />,
  [IconsEnum.Money]: <SavingsIcon />,
  [IconsEnum.Travel]: <AirplanemodeActiveIcon />,
  [IconsEnum.Car]: <TimeToLeaveIcon />,
  [IconsEnum.Education]: <SchoolIcon />,
  [IconsEnum.Lunch]: <LunchDiningIcon />,
  [IconsEnum.Theater]: <TheaterComedyIcon />,
  [IconsEnum.Sport]: <SportsBaseballIcon />,
  [IconsEnum.Camera]: <LinkedCameraIcon />,
}

function BookIcon({ book }: { book: Book }) {
  return iconTypeToIcon[book.icon] || <NotesIcon />;
}

export default BookIcon;
