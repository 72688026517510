import React from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import addWeeks from 'date-fns/addWeeks';

import WeekView from "./WeekView";
import { useAppSelector } from "../../App/hooks";
import { selectView } from "../../../redux/reducers/view";

function Page() {
  const view = useAppSelector(selectView);
  const showSecondColumn = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Box position="absolute" height="85%" width="100%">
      <Box display="flex" flexDirection="row" height="100%">
        <WeekView
          startDate={new Date(view.startDate)}
          endDate={new Date(view.endDate)}
        />
        {showSecondColumn && (
          <WeekView
            startDate={addWeeks(new Date(view.startDate), 1)}
            endDate={addWeeks(new Date(view.endDate), 1)}
          />
        )}
      </Box>
    </Box>
  );
}

export default Page;
