import React, { useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';

function Idle() {
  const onIdle = useCallback(() => {
    console.log("App is in idle state");
  }, []);
  const onActive = useCallback(() => {
    window.location.reload();
  }, []);

  useIdleTimer({
    onIdle,
    onActive
  })

  return (
    <div />
  );
}

export default Idle;
