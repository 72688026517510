import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import rowsReducer from '../../redux/reducers/rows';
import booksReducer from '../../redux/reducers/books';
import viewReducer from '../../redux/reducers/view';
import profileReducer from '../../redux/reducers/profile';

export const store = configureStore({
  reducer: {
    rows: rowsReducer,
    books: booksReducer,
    view: viewReducer,
    profile: profileReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
  >;