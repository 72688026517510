export enum IconsEnum {
  Notes = "notes",
  Work = "work",
  Money = "money",
  Travel = "travel",
  Car = "car",
  Education = "education",
  Lunch = "lunch",
  Theater = "theater",
  Sport = "sport",
  Camera = "camera",
}