import React, { useCallback } from 'react';
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useAppDispatch, useAppSelector } from "../../../../App/hooks";
import { setView } from "../../../../../redux/reducers/view";
import { selectProfile, updateProfileAsync } from "../../../../../redux/reducers/profile";

function WeekStartSelect() {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);

  const handleChange = useCallback((event: any) => {
    const newWeekStartsOn = Number.parseInt(event.target.value) as any;
    dispatch(updateProfileAsync({ weekStartsOn: newWeekStartsOn }));
    const now = new Date();
    dispatch(setView({
      startDate: +startOfWeek(now, { weekStartsOn: newWeekStartsOn }),
      endDate: +endOfWeek(now, { weekStartsOn: newWeekStartsOn }),
    }))
  }, [ dispatch ]);

  return (
    <ListItem>
      <ListItemIcon>
        <DateRangeIcon />
      </ListItemIcon>
      <ListItemText
        primary="Week starts on"
      />
      <FormControl>
        <NativeSelect
          fullWidth
          value={profile.weekStartsOn}
          onChange={handleChange}
          inputProps={{
            name: 'weekStartsOn',
            id: 'week-starts-on-select',
          }}
        >
          <option value={0}>Sunday</option>
          <option value={1}>Monday</option>
          <option value={2}>Tuesday</option>
          <option value={3}>Wednesday</option>
          <option value={4}>Thursday</option>
          <option value={5}>Friday</option>
          <option value={6}>Saturday</option>
        </NativeSelect>
      </FormControl>
    </ListItem>
  )
}

export default WeekStartSelect;
