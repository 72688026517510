import React, {useCallback, useEffect, useState} from 'react';
import sample from 'lodash.sample'
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FolderIcon from '@mui/icons-material/Folder';
import {useAppDispatch, useAppSelector} from "../../App/hooks";
import {jumpByWeek, selectView, setView} from "../../../redux/reducers/view";
import {Book, booksLoaded, createBookAsync, selectBooks} from "../../../redux/reducers/books";
import {IconsEnum} from "../../../constants/icons.enum";
import BookIcon from "../../common/BookIcon";
import {rowsLoaded} from "../../../redux/reducers/rows";
import {profileLoaded, selectProfile, updateProfileAsync} from "../../../redux/reducers/profile";
import config from "../../../config";
import FavoriteBookModeEnum from "../../../constants/favoriteBookMode.enum";

function Navigation() {
  const dispatch = useAppDispatch();
  const view = useAppSelector(selectView);
  const profile = useAppSelector(selectProfile);
  const books = useAppSelector(selectBooks);
  const isProfileLoaded = useAppSelector(profileLoaded);
  const areRowsLoaded = useAppSelector(rowsLoaded);
  const loaded = useAppSelector(booksLoaded);
  const [ bookInit, setBookInit ] = useState(false);

  useEffect(() => {
    if (loaded && books.length === 0) {
      // Create first book if none exist
      dispatch(createBookAsync({ name: "Notes", icon: IconsEnum.Notes }));
    }
    // eslint-disable-next-line
  }, [ books, loaded ]);

  useEffect(() => {
    // dispatch(updateProfileAsync({ lastOpenBookId: newValue }));
    if (!bookInit && isProfileLoaded && profile && books && books.length) {
      let newBookId: string | null = null;

      switch (profile.favoriteBookMode) {
        case FavoriteBookModeEnum.LastOpen: {
          if (profile.lastOpenBookId) {
            newBookId = profile.lastOpenBookId
          }
          break;
        }
        case FavoriteBookModeEnum.PinFirst: {
          if (profile.pinnedBookIds && profile.pinnedBookIds.length >= 1) {
            newBookId = profile.pinnedBookIds[0]
          }
          break;
        }
        case FavoriteBookModeEnum.PinSecond: {
          if (profile.pinnedBookIds && profile.pinnedBookIds.length >= 1) {
            newBookId = profile.pinnedBookIds[1]
          }
          break;
        }
        case FavoriteBookModeEnum.PinThird: {
          if (profile.pinnedBookIds && profile.pinnedBookIds.length >= 1) {
            newBookId = profile.pinnedBookIds[2]
          }
          break;
        }
        case FavoriteBookModeEnum.RandomPin: {
          if (profile.pinnedBookIds && profile.pinnedBookIds.length >= 1) {
            newBookId = sample(profile.pinnedBookIds) || null;
          }
          break;
        }
        case FavoriteBookModeEnum.RandomAll: {
          const randomBook = sample(books);
          if (randomBook) {
            newBookId = randomBook.id;
          }
          break;
        }
      }
      dispatch(setView({ bookId: newBookId }));
      if (newBookId && newBookId !== profile.lastOpenBookId) {
        dispatch(updateProfileAsync({ lastOpenBookId: newBookId }));
      }
      setBookInit(true);
    }
  }, [ dispatch, profile, books, isProfileLoaded, bookInit ]);

  const handleChange = useCallback((event: any, newValue: string) => {
    if (newValue === "prev") {
      // back
      dispatch(jumpByWeek(-1));
    } else if (newValue === "next") {
      // forward
      dispatch(jumpByWeek(1));
    } else if (newValue === "books_button") {
      dispatch(setView({ showBooksDialog: true }));
    } else {
      dispatch(setView({ bookId: newValue }));
      dispatch(updateProfileAsync({ lastOpenBookId: newValue }));
    }
  }, [ dispatch ]);

    let pinnedBooks: Book[] = [];
  let showBooksButton = false;
  if (profile.pinnedBookIds) {
    pinnedBooks = books.filter(book => (profile.pinnedBookIds || []).indexOf(book.id) >= 0);
    if (!pinnedBooks.length) {
      showBooksButton = true;
    }
  } else {
    pinnedBooks = books.slice(0, config.app.maxPinsCount);
  }

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={view.bookId || "initial"}
        onChange={handleChange}
      >
        <BottomNavigationAction
          label=""
          value="prev"
          icon={<ChevronLeftIcon />}
        />
        { loaded ? pinnedBooks.map(book => (
          <BottomNavigationAction
            key={book.id}
            label={book.name}
            value={book.id}
            icon={(!areRowsLoaded && view.bookId === book.id) ? <CloudQueueIcon /> : <BookIcon book={book} />}
          />
        )) : (
          <BottomNavigationAction
            label="Loading"
            icon={<CloudQueueIcon />}
            value="initial"
            disabled
          />
        ) }
        {showBooksButton && (
          <BottomNavigationAction
            value="books_button"
            label="Books"
            icon={<FolderIcon />}
          />
        )}
        <BottomNavigationAction
          label=""
          value="next"
          icon={<ChevronRightIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
}

export default Navigation;
