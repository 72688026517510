import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import debounce from 'lodash.debounce';
import { isMobile, isDesktop } from 'react-device-detect';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import isToday from 'date-fns/isToday'
import {getDayOfWeekFromDate} from "../../../../../utils/dayOfWeek";
import DateCircle from "./DateCircle";
import { upsertRowAsync, rowsLoaded, selectRowsData } from "../../../../../redux/reducers/rows";
import {useAppDispatch, useAppSelector} from "../../../../App/hooks";
import "./DayView.css";
import {selectView} from "../../../../../redux/reducers/view";
import DayOfWeekEnum from "../../../../../constants/dayOfWeek.enum";
import {selectProfile} from "../../../../../redux/reducers/profile";
import AutoFocusTodayModeEnum from "../../../../../constants/autoFocusTodayMode.enum";
import ThemeEnum from "../../../../../constants/theme.enum";

interface Props {
  date: Date;
  isFirstDayOfWeek: boolean;
  isLastDayOfWeek: boolean;
}

function DayView({ date, isFirstDayOfWeek, isLastDayOfWeek }: Props) {
  const [ touched, setTouched ] = useState<boolean>(false);
  const textRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const dayOfWeek = getDayOfWeekFromDate(date);
  const today = isToday(date);
  const view = useAppSelector(selectView);
  const profile = useAppSelector(selectProfile);
  const loaded = useAppSelector(rowsLoaded);
  const rd = useAppSelector(selectRowsData);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const index = 0;
  // eslint-disable-next-line
  const rows = (view.bookId && rd[view.bookId] && rd[view.bookId][year] && rd[view.bookId][year][month] && rd[view.bookId][year][month][day]) || {};
  const row = (view.bookId
    && rd[view.bookId]
    && rd[view.bookId][year]
    && rd[view.bookId][year][month]
    && rd[view.bookId][year][month][day]
    && rd[view.bookId][year][month][day][index]
  ) || null;
  const defaultValue = row ? row.text : "";
  const minRows = [DayOfWeekEnum.Saturday, DayOfWeekEnum.Sunday].indexOf(dayOfWeek) >= 0 ? 2 : 4;
  const autoFocus = useMemo(() => {
    if (!today) {
      return false;
    }

    switch (profile.autoFocusToday) {
      case AutoFocusTodayModeEnum.DesktopOnly: return isDesktop;
      case AutoFocusTodayModeEnum.MobileOnly: return isMobile;
      case AutoFocusTodayModeEnum.Always: return true;
      default: return false;
    }
  }, [today, profile.autoFocusToday]);

  const handleSave = useCallback((text: string) => {
    setTouched(true)
    if (!loaded || !view.bookId) {
      return;
    }
    dispatch(upsertRowAsync({
      year,
      month,
      day,
      text,
      index,
      bookId: view.bookId
    }));
  }, [ loaded, dispatch, year, month, day, index, view.bookId ]);

  const debouncedInputChange = debounce(async (event: any) => {
    handleSave(event.target.value)
  }, 300);

  useEffect(() => {
    if (textRef && textRef.current && autoFocus && loaded && today && !touched) {
      textRef.current.focus();
      setTouched(true);
    }
  }, [autoFocus, touched, loaded, today, textRef]);

  return (
    <Box mt={0.5}>
      <Box
        p={1}
        // elevation={0}
        className={`${profile.theme === ThemeEnum.Light ? "DayView" : "DayViewDark"} ` +
          `${isFirstDayOfWeek ? "DayView-first-day-of-week" : ""} ` +
          `${isLastDayOfWeek ? "DayView-last-day-of-week" : ""} `}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <DateCircle
            dayOfWeek={dayOfWeek}
            value={0}
            date={date}
          />
          <Box width="100%" pl={1} pr={1} height="100%">
            <TextField
              id={["text", String(view.bookId), dayOfWeek].join("-")}
              placeholder={dayOfWeek}
              variant="standard"
              fullWidth
              multiline
              inputRef={textRef}
              minRows={minRows}
              defaultValue={defaultValue}
              disabled={!loaded}
              onChange={debouncedInputChange}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DayView;
