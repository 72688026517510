import DayOfWeekEnum from "../constants/dayOfWeek.enum";

export const getDayOfWeekFromDate = (date: Date): DayOfWeekEnum => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
  switch (date.getDay()) {
    case 0: return DayOfWeekEnum.Sunday;
    case 1: return DayOfWeekEnum.Monday;
    case 2: return DayOfWeekEnum.Tuesday;
    case 3: return DayOfWeekEnum.Wednesday;
    case 4: return DayOfWeekEnum.Thursday;
    case 5: return DayOfWeekEnum.Friday;
    case 6: return DayOfWeekEnum.Saturday;
    default: throw new Error("getDayOfWeekFromDate: Expected value between 0 and 6");
  }
}