import React, {useEffect} from 'react';
// import Button from '@mui/material/Button';
// import Box from '@mui/material/Box';
import { useAuth0 } from "@auth0/auth0-react";
// import logo from '../../assets/logo.png';
import './LandingScreen.css';

function LandingScreen() {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
    // eslint-disable-next-line
  }, [ isLoading, isAuthenticated ]);

  return <div className="LandingScreen" />;
  //
  // return (
  //   <div className="LandingScreen">
  //     <header className="LandingScreen-header">
  //       <img src={logo} className="LandingScreen-logo" alt="logo" />
  //       <p>
  //         Welcome to the Notes App
  //       </p>
  //       <Box mt={4}>
  //         <Button variant="contained" size="large" onClick={() => loginWithRedirect()}>
  //           Log In
  //         </Button>
  //       </Box>
  //     </header>
  //   </div>
  // );
}

export default LandingScreen;
