import axios from 'axios';
import config from "../config";

export const getBooks = () => {
  return axios.get(`${config.api.url}/books`);
}

export const createBook = (body: any) => {
  return axios.post(`${config.api.url}/books`, body);
}

export const updateBook = (bookId: string, body: any) => {
  return axios.put(`${config.api.url}/books/${bookId}`, body);
}

export const archiveBook = (bookId: string) => {
  return axios.delete(`${config.api.url}/books/${bookId}`);
}