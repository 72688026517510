import React, { useCallback } from 'react';
import TelegramLoginButton from 'react-telegram-login';
import TelegramIcon from '@mui/icons-material/Telegram';
import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormControl from '@mui/material/FormControl';
import {useAppDispatch, useAppSelector} from "../../../../App/hooks";
import config from "../../../../../config";
import {selectProfile, linkTelegramAsync} from "../../../../../redux/reducers/profile";

function LinkTelegram() {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);

  const handleTelegramResponse = useCallback((response: any) => {
    dispatch(linkTelegramAsync(response));
  }, [ dispatch ]);

  const isLinked = !!profile.telegramUserId;

  return (
    <ListItem>
      <ListItemIcon>
        <TelegramIcon />
      </ListItemIcon>
      <ListItemText
        primary="Send via Telegram"
      />
      <FormControl>
        { isLinked ? (
          <Chip icon={<CheckIcon />} label="Connected" />
        ) : (
          <TelegramLoginButton
            dataOnauth={handleTelegramResponse}
            botName={config.telegram.bot}
            widgetVersion={21}
          />
        ) }
      </FormControl>
    </ListItem>
  )
}

export default LinkTelegram;
