import axios from 'axios';
import config from "../config";

export const getProfile = () => {
  return axios.get(`${config.api.url}/profile`);
}

export const updateProfile = (body: any) => {
  return axios.put(`${config.api.url}/profile`, body);
}

export const linkTelegram = (body: any) => {
  return axios.post(`${config.api.url}/profile/telegram`, body);
}

export const deleteProfile = () => {
  return axios.delete(`${config.api.url}/profile`);
}