import React, { useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ListItem from '@mui/material/ListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Theme} from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../App/hooks";
import {selectView, setView} from "../../../../redux/reducers/view";
import TransitionUp from "../../../common/TransitionUp";
import LinkTelegram from "./LinkTelegram";
import HowItWorks from "./HowItWorks";
import ChooseAiBook from "./ChooseAiBook";

function AiReckonDialog() {
  const dispatch = useAppDispatch();
  const view = useAppSelector(selectView);
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handleClose = useCallback(() => {
    dispatch(setView({ showAiReckonDialog: false }));
  }, [ dispatch ]);

  return (
    <Dialog
      open={view.showAiReckonDialog}
      onClose={handleClose}
      fullScreen={fullScreen}
      fullWidth
      TransitionComponent={fullScreen ? TransitionUp : undefined}
      maxWidth="md"
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            AI
          </Typography>
        </Toolbar>
      </AppBar>
      <List>
        <HowItWorks/>
        <ListItem>
          <Typography sx={{ mt: 2, mb: 1 }} variant="subtitle1" component="div"  color="darkgrey">
            Input
          </Typography>
        </ListItem>
        <LinkTelegram />
        <ListItem>
          <Typography sx={{ mt: 2, mb: 1 }} variant="subtitle1" component="div"  color="darkgrey">
            Output
          </Typography>
        </ListItem>
        <ChooseAiBook />
      </List>
    </Dialog>
  )
}

export default AiReckonDialog;
