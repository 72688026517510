import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../components/App/store'
import config from "../../config";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addWeeks from 'date-fns/addWeeks';
import ThemeEnum from "../../constants/theme.enum";

interface ViewState {
  bookId: string | null;
  weekStartsOn: 0 | 1;
  startDate: number;
  endDate: number;
  showAiReckonDialog: boolean;
  showBooksDialog: boolean;
  showCreateBookDialog: boolean;
  showEditBookDialog: string | false;
  showSettingsDialog: boolean;
  theme: ThemeEnum;
}

const now = new Date();
const initialState: ViewState = {
  bookId: null,
  weekStartsOn: config.app.weekStartsOn,
  startDate: +startOfWeek(now, { weekStartsOn: config.app.weekStartsOn }),
  endDate: +endOfWeek(now, { weekStartsOn: config.app.weekStartsOn }),
  showAiReckonDialog: false,
  showBooksDialog: false,
  showCreateBookDialog: false,
  showEditBookDialog: false,
  showSettingsDialog: false,
  theme: localStorage ? (localStorage.getItem("theme") as ThemeEnum) : ThemeEnum.Light,
};

export const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    setView: (state, action: PayloadAction<Partial<ViewState>>) => ({
      ...state,
      ...action.payload
    }),
    jumpByWeek: (state, action: PayloadAction<number>) => {
      if (!action.payload || action.payload === 0) {
        return state;
      }

      const newStartDate = addWeeks(new Date(state.startDate), action.payload);
      const newEndDate = addWeeks(new Date(state.endDate), action.payload);

      return {
        ...state,
        startDate: +newStartDate,
        endDate: +newEndDate,
      };
    }
  },
});

export const { setView, jumpByWeek } = viewSlice.actions;
export const selectView = (state: RootState) => state.view;
export default viewSlice.reducer;
