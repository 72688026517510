import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../components/App/store'
import { getBooks, createBook, updateBook, archiveBook } from '../../api/books'
import ApiStatusEnum from "../../constants/apiStatus.enum";
import {IconsEnum} from "../../constants/icons.enum";
import {BookTypeEnum} from "../../constants/bookType.enum";

export interface Book {
  id: string;
  name: string;
  icon: IconsEnum;
  type: BookTypeEnum;
  createdAt: string;
  archived?: boolean;
}

interface BooksState {
  list: Book[];
  loaded: boolean;
  status: ApiStatusEnum;
}

const initialState: BooksState = {
  list: [],
  loaded: false,
  status: ApiStatusEnum.Idle,
};

export const fetchBooksAsync = createAsyncThunk(
  'books/fetch',
  async () => {
    const response = await getBooks();
    // The value we return becomes the `fulfilled` action payload
    return response.data.list as Book[];
  }
);

export const createBookAsync = createAsyncThunk(
  'books/create',
  async (body: Partial<Book>) => {
    const response = await createBook(body);
    // The value we return becomes the `fulfilled` action payload
    return response.data as Book;
  }
);

export const updateBookAsync = createAsyncThunk(
  'books/update',
  async (book: Partial<Book>) => {
    const response = await updateBook(book.id!, book);
    // The value we return becomes the `fulfilled` action payload
    return response.data as Book;
  }
);

export const archiveBookAsync = createAsyncThunk(
  'books/archive',
  async (bookId: string) => {
    const response = await archiveBook(bookId);
    // The value we return becomes the `fulfilled` action payload
    return response.data as Book;
  }
);

export const booksSlice = createSlice({
  name: "books",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Book[]>) => ({
      ...state,
      list: action.payload
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBooksAsync.pending, (state: BooksState) => {
        state.status = ApiStatusEnum.Loading;
      })
      .addCase(fetchBooksAsync.fulfilled, (state: BooksState, action: PayloadAction<Book[]>) => {
        return {
          ...state,
          status: ApiStatusEnum.Idle,
          loaded: true,
          list: action.payload,
        }
      })
      .addCase(fetchBooksAsync.rejected, (state: BooksState) => {
        state.status = ApiStatusEnum.Failed;
      })
      .addCase(createBookAsync.fulfilled, (state: BooksState, action: PayloadAction<Book>) => {
        return {
          ...state,
          list: [...state.list, action.payload],
        }
      })
      .addCase(updateBookAsync.fulfilled, (state: BooksState, action: PayloadAction<Book>) => {
        return {
          ...state,
          list: state.list.map(item => item.id === action.payload.id ? action.payload : item)
        }
      })
      .addCase(archiveBookAsync.fulfilled, (state: BooksState, action: PayloadAction<Book>) => {
        return {
          ...state,
          list: state.list.map(item => item.id === action.payload.id ? action.payload : item)
        }
      })
  },
});

export const { set } = booksSlice.actions;
export const selectBooks = (state: RootState) => state.books.list;
export const booksApiStatus = (state: RootState) => state.books.status;
export const booksLoaded = (state: RootState) => state.books.loaded;
export default booksSlice.reducer;
