import React, { useCallback } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import PaletteIcon from '@mui/icons-material/Palette';
import { useAppDispatch, useAppSelector } from "../../../../App/hooks";
import ThemeEnum from "../../../../../constants/theme.enum";
import {selectView, setView} from "../../../../../redux/reducers/view";

const options = [
  { value: ThemeEnum.Light, label: "Light" },
  { value: ThemeEnum.Dark, label: "Dark" },
];


function Theme() {
  const dispatch = useAppDispatch();
  const view = useAppSelector(selectView);

  const handleChange = useCallback((event: any) => {
    const value = event.target.value;
    dispatch(setView({ theme: value }));
    if (localStorage && localStorage.setItem) {
      localStorage.setItem("theme", value)
    }
  }, [ dispatch ]);

  return (
    <ListItem>
      <ListItemIcon>
        <PaletteIcon />
      </ListItemIcon>
      <ListItemText
        primary="Theme"
      />
      <FormControl>
        <NativeSelect
          fullWidth
          value={view.theme}
          onChange={handleChange}
          inputProps={{
            name: 'theme',
            id: 'theme-mode-select',
          }}
        >
          { options.map((item) => (
            <option
              key={item.value}
              value={item.value}
            >
              {item.label}
            </option>
          )) }
        </NativeSelect>
      </FormControl>
    </ListItem>
  )
}

export default Theme;
