import React, {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useAppDispatch, useAppSelector } from "../../../App/hooks";
import {selectView, setView} from "../../../../redux/reducers/view";
import {selectBooks, updateBookAsync} from "../../../../redux/reducers/books";
import BookIcon from "../../../common/BookIcon";
import {IconsEnum} from "../../../../constants/icons.enum";

function EditBookDialog() {
  const dispatch = useAppDispatch();
  const view = useAppSelector(selectView);
  const books = useAppSelector(selectBooks);
  const [ name, setName ] = useState("");
  const [ icon, setIcon ] = useState(IconsEnum.Notes);

  const handleClose = useCallback(() => {
    dispatch(setView({ showEditBookDialog: false }));
  }, [ dispatch ]);

  const handleSave = useCallback(() => {
    if (view.showEditBookDialog) {
      dispatch(updateBookAsync({ id: view.showEditBookDialog, name, icon }));
      dispatch(setView({ showEditBookDialog: false }));
    }
  }, [ name, icon, dispatch, view.showEditBookDialog ]);

  const handleNameChange = useCallback((event: any) => {
    setName(event.target.value);
  }, []);

  const handleIconChange = useCallback((event: any) => {
    setIcon(event.target.value);
  }, []);

  const renderValue = useCallback((value: any) => {
    return (
      <Box display="flex" flexDirection="row">
        <BookIcon book={{ icon: value } as any} />
        <Box ml={1}>
          <Typography>
            {value}
          </Typography>
        </Box>
      </Box>
    )
  }, []);

  const book = books.find(item => item.id === view.showEditBookDialog);

  useEffect(() =>{
    if (book) {
      setName(book.name);
      setIcon(book.icon);
    }
  }, [book])

  if (!view.showEditBookDialog || !book) {
    return null;
  }

  return (
    <Dialog
      open={!!view.showEditBookDialog}
      fullWidth
      maxWidth="sm"
    >
      <form>
        <DialogTitle>Edit book</DialogTitle>
        <DialogContent>
          {/*<DialogContentText>*/}
          {/*  Enter book name and icon*/}
          {/*</DialogContentText>*/}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={handleNameChange}
          />

          <Box mt={2}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={icon}
                label="Icon"
                variant="standard"
                placeholder="Icon"
                onChange={handleIconChange}
                renderValue={renderValue}
              >
                { Object.values(IconsEnum).map(value => (
                  <MenuItem value={value} key={value}>
                    <ListItemIcon>
                      <BookIcon book={{ icon: value } as any} />
                    </ListItemIcon>
                    <ListItemText>
                      {value}
                    </ListItemText>
                  </MenuItem>
                )) }
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} disabled={!name}>Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default EditBookDialog;
