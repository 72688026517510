const config = {
  api: {
    url: process.env.REACT_APP_API_URL,
  },
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: "https://api.lanote.io/",
    scope: "read:rows read:books create:rows create:books update:rows update:books delete:rows delete:books"
  },
  telegram: {
    bot: process.env.REACT_APP_TELEGRAM_BOT_NAME
  },
  app: {
    weekStartsOn: 1 as 1, // https://date-fns.org/v2.29.3/docs/startOfWeek
    maxPinsCount: 3,
    logoutUrl: process.env.REACT_APP_LOGOUT_URL || window.location.origin,
    isLocalhost: String(process.env.REACT_APP_API_URL).includes("localhost")
  }
}

export default config;