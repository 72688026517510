import React, {useCallback, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Theme} from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../App/hooks";
import {selectView, setView} from "../../../../redux/reducers/view";
import {selectBooks} from "../../../../redux/reducers/books";
import TransitionUp from "../../../common/TransitionUp";
import BookListItem from "./BookListItem";

function BooksDialog() {
  const dispatch = useAppDispatch();
  const view = useAppSelector(selectView);
  const books = useAppSelector(selectBooks);
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [ showArchived, setShowArchived ] = useState(false)

  const activeBooks = books.filter(item => !item.archived);
  const archivedBooks = books.filter(item => item.archived);

  const handleClose = useCallback(() => {
    dispatch(setView({ showBooksDialog: false }));
  }, [ dispatch ]);

  const handleCreate = useCallback(() => {
    dispatch(setView({ showCreateBookDialog: true }));
  }, [ dispatch ]);

  const handleToggleArchived = useCallback(() => {
    setShowArchived(prev => !prev)
  }, []);

  let archivedToggleLabel = archivedBooks.length === 1 ? "Show 1 archived book" : `Show ${archivedBooks.length} archived books`;
  if (showArchived) {
    archivedToggleLabel = archivedBooks.length === 1 ? "Hide 1 archived book" : `Hide ${archivedBooks.length} archived books`;
  }

  const booksToShow = !showArchived ? activeBooks : [...activeBooks, ...archivedBooks];

  return (
    <Dialog
      open={view.showBooksDialog}
      onClose={handleClose}
      fullScreen={fullScreen}
      fullWidth
      TransitionComponent={fullScreen ? TransitionUp : undefined}
      maxWidth="md"
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Books
          </Typography>
        </Toolbar>
      </AppBar>
      <List>
        { booksToShow.map((item) =>
          <BookListItem
            book={item}
            // showDivider={index !== booksToShow.length - 1}
            showDivider
          />
        ) }
        { (archivedBooks.length > 0) && (
          <ListItem button onClick={handleToggleArchived}>
            <ListItemIcon>
              <DeleteForeverIcon />
            </ListItemIcon>
            <ListItemText primary={archivedToggleLabel} />
          </ListItem>
        ) }
        <ListItem button onClick={handleCreate}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            primary="Create new book"
            // secondary="Max 5"
          />
        </ListItem>
      </List>
    </Dialog>
  )
}

export default BooksDialog;
