import React, { useCallback } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import { useAppDispatch, useAppSelector } from "../../../../App/hooks";
import {selectProfile, updateProfileAsync} from "../../../../../redux/reducers/profile";
import FavoriteBookMode from "../../../../../constants/favoriteBookMode.enum";

const defaultOptions = [
  { value: FavoriteBookMode.LastOpen, label: "Last open" },
  { value: FavoriteBookMode.RandomAll, label: "Random" },
];

const getOptions = (pinsCount: number) => {
  return [
    ...defaultOptions,
    pinsCount >= 1 ?
      {
        value: FavoriteBookMode.RandomPin,
        label: "Random Pin"
      } :
      {
        value: FavoriteBookMode.RandomPin,
        label: "Random Pin (No pins)",
        disabled: true,
      },
    pinsCount >= 1 ?
      {
        value: FavoriteBookMode.PinFirst,
        label: "First Pin"
      } :
      {
        value: FavoriteBookMode.PinFirst,
        label: "First Pin (Not selected)",
        disabled: true,
      },
    pinsCount >= 2 ?
      {
        value: FavoriteBookMode.PinSecond,
        label: "Second Pin"
      } :
      {
        value: FavoriteBookMode.PinSecond,
        label: "Second Pin (Not selected)",
        disabled: true,
      },
    pinsCount >= 3 ?
      {
        value: FavoriteBookMode.PinThird,
        label: "Third Pin"
      } :
      {
        value: FavoriteBookMode.PinThird,
        label: "Third Pin (Not selected)",
        disabled: true,
      },
  ]
}

function FavoriteBook() {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);

  const handleChange = useCallback((event: any) => {
    dispatch(updateProfileAsync({ favoriteBookMode: event.target.value }));
  }, [ dispatch ]);

  const options = getOptions((profile.pinnedBookIds || []).length);

  return (
    <ListItem>
      <ListItemIcon>
        <GradeOutlinedIcon />
      </ListItemIcon>
      <ListItemText
        primary="Favorite book"
        secondary="opens by default"
      />
      <FormControl>
        <NativeSelect
          fullWidth
          value={profile.favoriteBookMode}
          onChange={handleChange}
          inputProps={{
            name: 'favoriteBook',
            id: 'favorite-book-select',
          }}
        >
          { options.map((item: any) => (
            <option
              key={item.value}
              value={item.value}
              disabled={item.disabled || false}
            >
              {item.label}
            </option>
          )) }
        </NativeSelect>
      </FormControl>
    </ListItem>
  )
}

export default FavoriteBook;
