import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Auth0Provider } from "@auth0/auth0-react";
import './index.css';
import config from "./config";
import App from './components/App';
import { store } from './components/App/store';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.auth0.domain!}
      clientId={config.auth0.clientId!}
      audience={config.auth0.audience!}
      scope={config.auth0.scope!}
      redirectUri={window.location.origin}
      useRefreshTokens
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
