import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {ThemeProvider} from '@mui/material/styles';
import LandingScreen from "../LandingScreen";
import HomeScreen from "../HomeScreen";
import {getTheme} from '../../constants/theme'
import {useAppSelector} from "./hooks";
import ThemeEnum from "../../constants/theme.enum";
import {selectView} from "../../redux/reducers/view";

function App() {
  const { isAuthenticated } = useAuth0();
  const view = useAppSelector(selectView);

  return (
    <ThemeProvider theme={getTheme(view.theme || ThemeEnum.Light)}>
      {isAuthenticated ? <HomeScreen /> : <LandingScreen />}
    </ThemeProvider>
  )
}

export default App;
