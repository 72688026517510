import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../components/App/store';
import { getProfile, updateProfile, linkTelegram } from '../../api/profile';
import ApiStatusEnum from "../../constants/apiStatus.enum";
import FavoriteBookModeEnum from "../../constants/favoriteBookMode.enum";
import AutoFocusTodayModeEnum from "../../constants/autoFocusTodayMode.enum";
import ThemeEnum from "../../constants/theme.enum";
import Plans from "../../constants/plan.enum";

export interface Profile {
  id: string;
  createdAt: string;
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  pinnedBookIds?: string[];
  lastOpenBookId?: string;
  defaultAiBookId?: string;
  favoriteBookMode?: FavoriteBookModeEnum;
  autoFocusToday?: AutoFocusTodayModeEnum;
  theme?: ThemeEnum;
  plan?: Plans;
  telegramUserId?: string;
}

interface ProfileState {
  data: Profile;
  loaded: boolean;
  status: ApiStatusEnum;
}

const defaultProfile = {
  weekStartsOn: 1,
  favoriteBookMode: FavoriteBookModeEnum.LastOpen,
  autoFocusToday: AutoFocusTodayModeEnum.Off,
} as any as Profile;

const initialState: ProfileState = {
  data: defaultProfile,
  loaded: false,
  status: ApiStatusEnum.Idle,
};

export const fetchProfileAsync = createAsyncThunk(
  'profile/fetch',
  async () => {
    const response = await getProfile();
    // The value we return becomes the `fulfilled` action payload
    return response.data as Profile;
  }
);

export const updateProfileAsync = createAsyncThunk(
  'profile/update',
  async (body: Partial<Profile>) => {
    const response = await updateProfile(body);
    // The value we return becomes the `fulfilled` action payload
    return response.data as Profile;
  }
);

export const linkTelegramAsync = createAsyncThunk(
  'profile/link_telegram',
  async (body: Partial<Profile>) => {
    const response = await linkTelegram(body);
    // The value we return becomes the `fulfilled` action payload
    return response.data as Profile;
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Profile>) => ({
      ...state,
      data: action.payload
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileAsync.pending, (state: ProfileState) => {
        state.status = ApiStatusEnum.Loading;
      })
      .addCase(fetchProfileAsync.fulfilled, (state: ProfileState, action: PayloadAction<Profile>) => {
        return {
          ...state,
          status: ApiStatusEnum.Idle,
          loaded: true,
          data: {
            ...defaultProfile,
            ...action.payload
          },
        }
      })
      .addCase(fetchProfileAsync.rejected, (state: ProfileState) => {
        state.status = ApiStatusEnum.Failed;
      })
      .addCase(updateProfileAsync.fulfilled, (state: ProfileState, action: PayloadAction<Profile>) => {
        return {
          ...state,
          data: action.payload,
        }
      })
      .addCase(linkTelegramAsync.fulfilled, (state: ProfileState, action: PayloadAction<Profile>) => {
        return {
          ...state,
          data: action.payload,
        }
      });
  },
});

export const { set } = profileSlice.actions;
export const selectProfile = (state: RootState) => state.profile.data;
export const profileApiStatus = (state: RootState) => state.profile.status;
export const profileLoaded = (state: RootState) => state.profile.loaded;
export default profileSlice.reducer;
