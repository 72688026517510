import React, {useEffect} from 'react';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import { useAuth0 } from "@auth0/auth0-react";

import {fetchRowsAsync} from "../../redux/reducers/rows";
import { useAppDispatch, useAppSelector } from "../App/hooks";
import {selectView} from "../../redux/reducers/view";
import {fetchBooksAsync} from "../../redux/reducers/books";
import {fetchProfileAsync} from "../../redux/reducers/profile";

import './HomeScreen.css';
import HeadBar from "./HeadBar";
import Page from "./Page";
import Navigation from "./Navigation";
import BooksDialog from "./Dialogs/BooksDialog";
import CreateBookDialog from "./Dialogs/CreateBookDialog";
import SettingsDialog from "./Dialogs/SettingsDialog";
import EditBookDialog from "./Dialogs/EditBookDialog";
import AiReckonDialog from "./Dialogs/AiReckonDialog";
import Idle from "./Idle";

function HomeScreen() {
  const dispatch = useAppDispatch();
  const view = useAppSelector(selectView);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently()
      .then(token => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        dispatch(fetchProfileAsync());
        dispatch(fetchBooksAsync());
      });
    // eslint-disable-next-line
  }, []);

  // fetch dates
  useEffect(() => {
    if (view.bookId) {
      const startDate = new Date(view.startDate);
      const year = startDate.getFullYear();
      const month = startDate.getMonth() + 1;
      dispatch(fetchRowsAsync({ year, month, bookId: view.bookId }));

      const prevMonthView = { year, month: month - 1, bookId: view.bookId };
      if (prevMonthView.month < 1) {
        prevMonthView.year -= 1;
        prevMonthView.month = 12;
      }
      dispatch(fetchRowsAsync(prevMonthView));

      const nextMonthView = { year, month: month + 1, bookId: view.bookId };
      if (nextMonthView.month > 12) {
        nextMonthView.year += 1;
        nextMonthView.month = 1;
      }
      dispatch(fetchRowsAsync(nextMonthView));
    }
    // eslint-disable-next-line
  }, [ view.startDate, view.bookId ])

  return (
    <div className="HomeScreen">
      <CssBaseline />
      <HeadBar />
      <Page />
      <Navigation />
      <BooksDialog />
      <CreateBookDialog />
      <EditBookDialog />
      <SettingsDialog />
      <AiReckonDialog />
      <Idle />
    </div>
  );
}

export default HomeScreen;
