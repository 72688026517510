import {createTheme, Theme} from '@mui/material/styles';
import ThemeEnum from "./theme.enum";

const overrides = {
  shape: {
    borderRadius: 0,
  },
};

export const getTheme = (code: ThemeEnum): Theme => {
  return createTheme({
    palette: {
      mode: code,
      background: {
        default: code === ThemeEnum.Light ? "#f9fafc" : "#121212",
        paper: code === ThemeEnum.Light ? "#fff" : "#121212"
      },
      primary: {
        main: "#f93944",
      },
      secondary: {
        main: "#eeeedd",
      },
    },
    ...overrides,
  });
}
