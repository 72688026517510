import React, { useCallback } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Stack from '@mui/material/Stack';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import Button from "@mui/material/Button";
import {useAuth0} from "@auth0/auth0-react";
import config from "../../../../../config";
import {deleteProfile} from "../../../../../api/profile";


function DeleteAccount() {
  const { logout } = useAuth0();

  const handleDelete = useCallback(async () => {
    const confirmed = window.confirm([
      "Sorry to see you go, but we value your privacy rights",
      "",
      "This is what's going to happen:",
      "* You will be logged out",
      // "* We will send email and start counting 90 days after you push the button",
      "* We start counting 90 days after you push the button",
      "* If you do not log in back during this period, data will be permanently deleted",
      "* Otherwise, deletion request will be canceled",
      "",
      "FYI, we won't be able to recover books, notes or your profile.",
      "Do you wish to continue?"
    ].join("\n"));
    if (confirmed) {
      await deleteProfile();
      logout({ returnTo: config.app.logoutUrl });
    }
  }, [ logout ]);

  return (
    <ListItem>
      <ListItemIcon>
        <ShieldOutlinedIcon />
      </ListItemIcon>
      <ListItemText
        primary="Your Account"
      />
      <Stack spacing={2} direction="row">
        <Button variant="outlined" color="secondary" onClick={handleDelete}>Delete</Button>
      </Stack>
    </ListItem>
  )
}

export default DeleteAccount;
