import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import getWeek from 'date-fns/getWeek'
import DayView from "./DayView";
import {splitIntervalIntoDates} from "../../../../utils/interval";
import "./WeekView.css"
import {useAppSelector} from "../../../App/hooks";
import {selectView} from "../../../../redux/reducers/view";
import {selectProfile} from "../../../../redux/reducers/profile";
import ThemeEnum from "../../../../constants/theme.enum";

interface Props {
  startDate: Date;
  endDate: Date;
}

function WeekView({ startDate, endDate }: Props) {
  const days = splitIntervalIntoDates(startDate, endDate);
  const view = useAppSelector(selectView);
  const profile = useAppSelector(selectProfile);
  const weekNumber = getWeek(startDate,  { weekStartsOn: profile.weekStartsOn });

  return (
    <Box width="100%" p={2}>
      <Paper elevation={0} className={profile.theme === ThemeEnum.Light ? "WeekView" : "WeekViewDark"}>
        {days.map((date, index) => (
          <DayView
            key={[String(view.bookId), date.toISOString()].join("-")}
            date={date}
            isFirstDayOfWeek={index === 0}
            isLastDayOfWeek={index === (days.length - 1)}
          />
        ))}
      </Paper>
      <Box p={2}>
        <Typography variant="overline" color="grey">
          Week {weekNumber}
        </Typography>
      </Box>
    </Box>
  );
}

export default WeekView;
