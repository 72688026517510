import React, { useEffect, useState } from 'react';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Alert from '@mui/material/Alert';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormControl from '@mui/material/FormControl';
import NativeSelect from "@mui/material/NativeSelect";
import config from "../../../../../config";
import { useAppDispatch, useAppSelector } from "../../../../App/hooks";
import { selectProfile, updateProfileAsync } from "../../../../../redux/reducers/profile";
import { booksLoaded, createBookAsync, selectBooks } from "../../../../../redux/reducers/books";
import { BookTypeEnum } from "../../../../../constants/bookType.enum";
import { IconsEnum } from "../../../../../constants/icons.enum";

function ChooseAiBook() {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const books = useAppSelector(selectBooks);
  const areBooksLoaded = useAppSelector(booksLoaded);
  const [ firstAiBookCreated, setFirstAiBookCreated ] = useState(false);

  const activeAiBooks = books.filter(item => item.type === BookTypeEnum.AiBasic);
  const isLinked = !!profile.telegramUserId;

  useEffect(() => {
    if (isLinked && areBooksLoaded && !activeAiBooks.length) {
      dispatch(createBookAsync({ name: "AI", icon: IconsEnum.Camera, type: BookTypeEnum.AiBasic }));
      setFirstAiBookCreated(true);
    }
  }, [ isLinked, activeAiBooks, areBooksLoaded, dispatch ]);

  useEffect(() => {
    if (firstAiBookCreated && activeAiBooks.length > 0) {
      const bookId = activeAiBooks[0].id;
      dispatch(updateProfileAsync({ defaultAiBookId: bookId }));
    }
  }, [ activeAiBooks, firstAiBookCreated, dispatch ])

  return (
    <ListItem>
      <ListItemIcon>
        <MenuBookIcon />
      </ListItemIcon>
      <ListItemText
        primary="Select AI Book"
      />
      <FormControl>
        { (isLinked || config.app.isLocalhost) ? (
          <NativeSelect
            fullWidth
            value={profile.defaultAiBookId || ""}
            onChange={() => {}}
            inputProps={{
              name: 'defaultAiBookId',
              id: 'default-ai-book-select',
            }}
          >
            { activeAiBooks.length > 0 ?
              activeAiBooks.map((item) => (
                <option
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </option>
              )) : (
                <option value="" disabled>
                  No AI Books found
                </option>
              ) }
          </NativeSelect>
        ) : (
          <Alert severity="warning">
            Configure Input first
          </Alert>
        ) }
      </FormControl>
    </ListItem>
  )
}

export default ChooseAiBook;
