import React, { useCallback } from 'react';
import format from 'date-fns/format';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAppDispatch, useAppSelector } from "../../../../App/hooks";
import {selectView, setView} from "../../../../../redux/reducers/view";
import {archiveBookAsync, selectBooks, updateBookAsync} from "../../../../../redux/reducers/books";
import BookIcon from "../../../../common/BookIcon";
import {selectProfile, updateProfileAsync} from "../../../../../redux/reducers/profile";
import config from "../../../../../config";
import {Book} from "../../../../../redux/reducers/books";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material/styles";

interface Props {
  book: Book;
  showDivider: boolean;
}

const ITEM_HEIGHT = 48;

function BookListItem({ book, showDivider }: Props) {
  const dispatch = useAppDispatch();
  const view = useAppSelector(selectView);
  const books = useAppSelector(selectBooks);
  const profile = useAppSelector(selectProfile);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleSelect = useCallback((bookId: string) => {
    dispatch(setView({ bookId, showBooksDialog: false }));
    dispatch(updateProfileAsync({ lastOpenBookId: bookId }));
  }, [ dispatch ]);

  const handleEdit = useCallback((bookId: string) => {
    dispatch(setView({ showEditBookDialog: bookId }));
    handleClose();
  }, [ dispatch, handleClose ]);

  const handleArchive = useCallback((bookId: string) => {
    const book = books.find(item => item.id === bookId);
    if (!book) {
      return
    }
    const confirmed = window.confirm(`Do you want to archive ${book.name}? You can restore it later`);
    if (confirmed) {
      dispatch(archiveBookAsync(book.id));
    }
    handleClose();
  }, [ dispatch, books, handleClose ]);

  const handleRestore = useCallback((bookId: string) => {
    const book = books.find(item => item.id === bookId);
    if (!book) {
      return
    }
    dispatch(updateBookAsync({ ...book, archived: false }));
    handleClose();
  }, [ dispatch, books, handleClose ]);

  // eslint-disable-next-line
  const currentPins = profile.pinnedBookIds || [];

  const handlePin = useCallback((bookId: string) => {
    if (currentPins.indexOf(bookId) >= 0) {
      // remove
      const newPins = currentPins.filter(item => item !== bookId);
      dispatch(updateProfileAsync({ pinnedBookIds: newPins }))
    } else {
      // add
      const prevPins = currentPins.slice(0, config.app.maxPinsCount - 1);
      dispatch(updateProfileAsync({ pinnedBookIds: [ bookId, ...prevPins] }))
    }
    handleClose();
    // dispatch(setView({ bookId, showBooksDialog: false }));
  }, [ dispatch, currentPins, handleClose ]);

  const description = [];
  if (!smallScreen) {
    description.push(format(new Date(book.createdAt), "PPP"))
  } else {
    description.push(format(new Date(book.createdAt), "PP"))
  }
  if (book.id === view.bookId) {
    description.push("Selected");
  }
  if (book.archived) {
    description.push("Archived");
  }
  const bookComponent = (
    <ListItem
      key={book.id}
    >
      <ListItemIcon>
        <BookIcon book={book} />
      </ListItemIcon>
      <ListItemText
        primary={book.name}
        secondary={description.join(" • ")}
      />
      <ListItemSecondaryAction>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          <MenuItem onClick={() => book.archived ? handleRestore(book.id) : handleArchive(book.id)}>
            {book.archived ? "Restore" : "Archive"}
          </MenuItem>
          <MenuItem onClick={() => handleEdit(book.id)}>
            Edit
          </MenuItem>
          <MenuItem onClick={() => handlePin(book.id)}>
            { (currentPins.indexOf(book.id) >= 0 ) ? "Unpin" : "Pin"}
          </MenuItem>
        </Menu>
        <IconButton
          color="inherit"
          onClick={() => handleSelect(book.id)}
          aria-label="select"
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
  if (!showDivider) {
    return bookComponent;
  }
  return <>
    {bookComponent}
    <Divider key={["divider", book.id].join("-")} />
  </>;
}

export default BookListItem;
