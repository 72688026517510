import React, { useCallback } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { useAppDispatch, useAppSelector } from "../../../../App/hooks";
import {selectProfile, updateProfileAsync} from "../../../../../redux/reducers/profile";
import AutoFocusTodayModeEnum from "../../../../../constants/autoFocusTodayMode.enum";

const options = [
  { value: AutoFocusTodayModeEnum.Off, label: "Off" },
  { value: AutoFocusTodayModeEnum.DesktopOnly, label: "On Desktop" },
  { value: AutoFocusTodayModeEnum.MobileOnly, label: "On Mobile or Tablet" },
  { value: AutoFocusTodayModeEnum.Always, label: "Always" },
];


function AutoFocusToday() {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);

  const handleChange = useCallback((event: any) => {
    dispatch(updateProfileAsync({ autoFocusToday: event.target.value }));
  }, [ dispatch ]);

  return (
    <ListItem>
      <ListItemIcon>
        <LocationSearchingIcon />
      </ListItemIcon>
      <ListItemText
        primary="Auto-focus on Today"
      />
      <FormControl>
        <NativeSelect
          fullWidth
          value={profile.autoFocusToday}
          onChange={handleChange}
          inputProps={{
            name: 'autoFocusToday',
            id: 'auto-focus-on-today-mode-select',
          }}
        >
          { options.map((item) => (
            <option
              key={item.value}
              value={item.value}
            >
              {item.label}
            </option>
          )) }
        </NativeSelect>
      </FormControl>
    </ListItem>
  )
}

export default AutoFocusToday;
