import React, {useCallback} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import format from 'date-fns/format';
import {useAuth0} from "@auth0/auth0-react";
import {useAppDispatch, useAppSelector} from "../../App/hooks";
import {selectView, setView} from "../../../redux/reducers/view";
import config from "../../../config";
import {selectProfile} from "../../../redux/reducers/profile";
import Plans from "../../../constants/plan.enum";

function HeadBar() {
  const { user, logout } = useAuth0();
  const dispatch = useAppDispatch();
  const view = useAppSelector(selectView);
  const profile = useAppSelector(selectProfile);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleAiReckonClick = useCallback(() => {
    dispatch(setView({ showAiReckonDialog: true }));
  }, [ dispatch ]);

  const handleBooksClick = useCallback(() => {
    dispatch(setView({ showBooksDialog: true }));
  }, [ dispatch ]);

  const handleSettingsClick = useCallback(() => {
    dispatch(setView({ showSettingsDialog: true }));
  }, [ dispatch ]);

  const monthFormatted = format(view.startDate, "LLLL yy").toUpperCase();

  return (
    <AppBar position="static" color="secondary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.2rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {monthFormatted}
          </Typography>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {monthFormatted}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open menu">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user?.name} src={user?.picture} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem disabled>
                <Typography textAlign="center">{user?.name}</Typography>
              </MenuItem>
              <MenuItem disabled>
                <Typography textAlign="center">{user?.email}</Typography>
              </MenuItem>
              <Divider />
              { (profile.plan === Plans.AAA || config.app.isLocalhost) && (
                <MenuItem onClick={handleAiReckonClick}>
                  <Typography textAlign="center">
                    AI
                  </Typography>
                </MenuItem>
              ) }
              <MenuItem onClick={handleBooksClick}>
                <Typography textAlign="center">Books</Typography>
              </MenuItem>
              <MenuItem onClick={handleSettingsClick}>
                <Typography textAlign="center">Settings</Typography>
              </MenuItem>
              <MenuItem onClick={() => logout({ returnTo: config.app.logoutUrl })}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default HeadBar;
