import axios from 'axios';
import config from "../config";

export const getRows = (bookId: string, year: number, month: number) => {
  return axios.get(`${config.api.url}/books/${bookId}/rows/${year}/${month}`)
}

export const upsertRow = (bookId: string, year: number, month: number, day: number, index: number, text: string) => {
  return axios.post(`${config.api.url}/books/${bookId}/rows/${year}/${month}/${day}/${index}`, {
    text
  })
}