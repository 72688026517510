import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../components/App/store'
import { getRows, upsertRow } from '../../api/rows'
import ApiStatusEnum from "../../constants/apiStatus.enum";

interface Row {
  id: string;
  bookId: string;
  text: string;
  date: string;
  day: number;
  month: number;
  year: number;
  index: number;
}

interface RowsState {
  list: Row[];
  data: {
    [bookId: string]: {
      [year: string]: {
        [month: string]: {
          [day: string]: {
            [index: number]: Row
          }
        }
      }
    }
  }
  loads: {
    [bookId: string]: {
      [year: string]: {
        [month: string]: number // timestamp
      }
    }
  }
  status: ApiStatusEnum;
  firstLoadComplete: boolean;
}

const initialState: RowsState = {
  list: [],
  data: {},
  loads: {},
  status: ApiStatusEnum.Idle,
  firstLoadComplete: false,
};

export const fetchRowsAsync = createAsyncThunk(
  'rows/fetch',
  async ({ year, month, bookId }: { year: number, month: number, bookId: string }) => {
    const response = await getRows(bookId, year, month);
    // The value we return becomes the `fulfilled` action payload
    return {
      list: response.data.list,
      bookId,
      year,
      month
    };
  }
);

export const upsertRowAsync = createAsyncThunk(
  'rows/upsert',
  async ({ year, month, day, text, index, bookId }: Pick<Row, "year" | "month" | "day" | "text"  | "index" | "bookId"> ) => {
    const response = await upsertRow(bookId, year, month, day, index, text);
    // The value we return becomes the `fulfilled` action payload
    return response.data as Row;
  }
);

export const rowsSlice = createSlice({
  name: "rows",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Row[]>) => ({
      ...state,
      list: action.payload
    }),
    resetRows: (state) => ({
      ...state,
      list: [],
      data: {},
      loads: {},
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRowsAsync.pending, (state: RowsState) => {
        state.status = ApiStatusEnum.Loading;
      })
      .addCase(fetchRowsAsync.fulfilled, (state: RowsState, action: PayloadAction<{ list: Row[], year: number, month: number, bookId: string }>) => {
        return {
          ...state,
          status: ApiStatusEnum.Idle,
          firstLoadComplete: true,
          list: action.payload.list,
          loads: {
            ...state.loads,
            [action.payload.bookId]: {
              ...(state.data[action.payload.bookId] || {}),
              [action.payload.year]: {
                ...(state.loads[action.payload.year] || {}),
                [action.payload.month]: +new Date(),
              } as Record<string, Record<string, number>>
            }
          },
          data: {
            ...state.data,
            [action.payload.bookId]: {
              ...(state.data[action.payload.bookId] || {}),
              [action.payload.year]: {
                ...((state.data[action.payload.bookId] || {})[action.payload.year] || {}),
                [action.payload.month]: {
                  ...(((state.data[action.payload.bookId] || {})[action.payload.year] || {})[action.payload.month] || {}),
                  ...action.payload.list.reduce((result, item) => ({
                    ...result,
                    [item.day]: {
                      ...(result[item.day] || {}),
                      // Fallback to zero for old records
                      [item.index || "0"]: item,
                    }
                  }), {} as Record<string, Row[]>)
                }
              }
            }
          }
        }
      })
      .addCase(upsertRowAsync.fulfilled, (state: RowsState, action: PayloadAction<Row & { id: string | null }>) => {
        return {
          ...state,
          data: {
            ...state.data,
            [action.payload.bookId]: {
              [action.payload.year]: {
                ...((state.data[action.payload.bookId] || {})[action.payload.year] || {}),
                [action.payload.month]: {
                  ...(((state.data[action.payload.bookId] || {})[action.payload.year] || {})[action.payload.month] || {}),
                  [action.payload.day]: {
                    ...((((state.data[action.payload.bookId] || {})[action.payload.year] || {})[action.payload.month] || {})[action.payload.day] || {}),
                    [action.payload.index]: action.payload,
                  },
                }
              }
            },

          }
        }
      });
  },
});

export const { set, resetRows } = rowsSlice.actions;
export const selectRowsData = (state: RootState) => state.rows.data;
export const rowsLoaded = (state: RootState) => state.rows.status === ApiStatusEnum.Idle && state.rows.firstLoadComplete;
export default rowsSlice.reducer;
