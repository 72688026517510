import React from 'react';
import isToday from 'date-fns/isToday'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import DayOfWeekEnum from "../../../../../../constants/dayOfWeek.enum";

interface Props {
  dayOfWeek: DayOfWeekEnum;
  value: number;
  date: Date;
}

function DateCircle({ dayOfWeek, value, date }: Props) {
  return (
    <Box p={1}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" color="secondary" value={value} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            // variant="caption"
            component="div"
            color={ isToday(date) ? "primary" : "text.secondary" }
          >
            <b>{date.getDate()}</b>
            {/*<br/>*/}
            {/*{dayOfWeek.slice(0, 2).toUpperCase()}*/}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default DateCircle;
